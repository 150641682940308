import * as DB from '@getmo/common/prisma/typebox';
import { type SchemedDecode } from '@getmo/common/schemed';
import { Nullable } from '@getmo/common/typebox';
import { Type } from '@getmo/common/vendor/typebox';

export enum PaymentStatus {
  Overdue = 'overdue',
  Paid = 'paid',
  Today = 'today',
  Upcoming = 'upcoming',
}

export const Onboarding = Type.Pick(DB.Onboarding, [
  'id',
  'name',
  'phone',
  'email',
  'status',
  'region',
  'location',
  'shopName',
  'loanAmount',
  'submittedAt',
  'loanDuration',
  'typeOfSalesChannel',
]);
export type Onboarding = SchemedDecode<typeof Onboarding>;
export const TermsSuggestion = Type.Pick(DB.TermsSuggestion, [
  'date',
  'rate',
  'term',
  'amount',
  'productType',
  'paymentFrequency',
  'originationFee',
  'holdback',
  'minPayment',
]);
export type TermsSuggestion = SchemedDecode<typeof TermsSuggestion>;
export const RepaymentSchedule = Type.Pick(DB.RepaymentSchedule, [
  'loanId',
  'date',
  'amount',
  'status',
  'paidAmount',
  'paymentDate',
]);
export type RepaymentSchedule = SchemedDecode<typeof RepaymentSchedule>;
export const Loan = Type.Pick(DB.Loan, ['amount', 'date', 'repaidAmount']);
export type Loan = SchemedDecode<typeof Loan>;
export const Repayment = Type.Pick(DB.Repayment, ['amount', 'date']);
export type Payment = SchemedDecode<typeof Repayment>;
export const VirtualAccount = Type.Pick(DB.VirtualAccount, ['number', 'name']);
export type VirtualAccount = SchemedDecode<typeof VirtualAccount>;
export const Underwriting = Type.Pick(DB.Underwriting, ['status', 'pipeline']);
export type Underwriting = SchemedDecode<typeof Underwriting>;
export const AyoconnectCard = Type.Pick(DB.AyoconnectCard, ['maskedCard', 'bankCode']);
export type AyoconnectCard = SchemedDecode<typeof AyoconnectCard>;

export const DisbursementState = Type.Pick(DB.DisbursementState, [
  'signature',
  'plannedDate',
  'destAccountType',
  'destAccountNumber',
  'disbursementRequested',
  'loanAgreementNumber',
  'applicantDateOfBirth',
]);

export type MyApplication = SchemedDecode<typeof MyApplication>;
export const MyApplication = Type.Composite([
  Onboarding,
  Type.Object({
    ayoconnectCards: Type.Array(AyoconnectCard),
    xenditCustomer: Nullable(Type.Pick(DB.XenditCustomer, ['onboardingId', 'paymentMethodDisplay'])),
    saleChannels: Type.Array(Type.Pick(DB.OnboardingSaleChannel, ['id', 'isSynced', 'link', 'type'])),
    underwriting: Nullable(
      Type.Composite([
        Underwriting,
        Type.Object({
          selectedTerms: Nullable(TermsSuggestion),
          disbursementState: Nullable(DisbursementState),
          loan: Nullable(
            Type.Composite([
              Loan,
              Type.Object({
                virtualAccount: VirtualAccount,
                repaymentSchedules: Type.Array(RepaymentSchedule),
              }),
            ]),
          ),
        }),
      ]),
    ),
  }),
]);
